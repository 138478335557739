import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
    text-align: justify;
    color: #111111;

    .show-more-button {
        padding: 1em;
    }

    * {
        font-family: ${vars.fontPrimary} !important;
    }

    h1 {
        font-size: 25px;
        font-weight: 800;
        margin-bottom: 1em;
        color: rgb(178, 144, 81)
    }

    h2 {
        font-size: 28px;
        font-weight: 300;
        margin: 1em 0;
        color: rgb(178, 144, 81);
    }

    h3 {
        font-size: 32px;
        font-weight: 300;
        margin-top: 2em;
        color: #000000;
    }

    h4 {
        font-size: 19px;
        font-weight: 800;
        margin: 2em 0;
        color: #000000;
    }

    h5 {
        font-size: 17px;
        font-weight: 900;
        margin-bottom: .25em;
        color: #000000;
    }

    ul {
        margin: 1em 0;
        list-style-type: disc;
    }

    ol {
        margin: 1em 0;
        list-style-type: decimal;
    }

    li {
        margin-left: 1.5em;
    }

    a {
        color: #b29051;
        text-decoration: underline;
    }

    i {
        font-style: italic;
    }

    b, strong {
        color: #111111;
        font-weight: 900;
    }

    p {
        line-height: 22px !important;
        font-size: 1em;
        color: #111111 !important;
        background-color: transparent !important;

        span {
            line-height: 22px !important;
            font-size: 1em;
            color: #111111 !important;
            background-color: transparent !important;
        }
    }

    img {
        height: auto;
        max-width: 100%;
        border-radius: .5em;
        width: 100%;
        margin: 2em auto;
    }

    pre, code {
        padding: 1em;
        background-color: #333333;
        color: white;
    }

    iframe {
        width: 100%;
        height: 60vh;
    }

    @media all and (max-width: ${vars.mobileM}) {
        text-align: left;

        .description-content {
            text-align: left;
        }
    }
`;
