import { FunctionComponent, useMemo } from 'react';
import withHydrationOnDemand from 'react-hydration-on-demand';
import { useRouter } from 'next/router';

import { Section } from 'types/sections';

import CmsSection from 'models/CmsSection';

import { ROUTES_FOR_DYNAMIC_REFRESH } from 'consts/routes';

import SectionWrapper from 'components/modules/public/cms/SectionWrapper';

import StyledComponent from './styles';
import { Props } from './types';

interface MergedSection extends Section {
    cmsSection: CmsSection;
}

const PublicCmsSectionsWrapper: FunctionComponent<Props> = ({ sections, cmsSections, memoRefresher }) => {
    const router = useRouter();
    const prepareSections = (argSections: Section[], argCmsSections: CmsSection[]) => {
        if (!argSections) return [];

        return argSections
            .filter((section: Section) => section.visible)
            .map((section: Section): MergedSection => {
                const foundCmsSection = argCmsSections?.find(cmsSection => cmsSection.slug === section.slug);
                const order = foundCmsSection?.order || section.order || 1;

                return {
                    ...section,
                    order,
                    cmsSection: foundCmsSection,
                    children: section.children,
                };
            });
    };

    const prepareCmsSections = (parsedSections: MergedSection[], argCmsSections: CmsSection[]) => {
        if (!argCmsSections) return [];

        return argCmsSections
            .filter(cmsSection => !parsedSections.find(parsedSection => parsedSection?.cmsSection?.slug === cmsSection.slug))
            .map((section: CmsSection, index): MergedSection => {
                const order = section.order || 0;

                return {
                    slug: section.slug,
                    visible: true,
                    order,
                    cmsSection: section,
                    children: () => (
                        <SectionWrapper
                            key={`${section.type}-${section.order}-${index}`}
                            cmsSection={section}
                        >
                            {(locale, override) => (override(locale))}
                        </SectionWrapper>
                    ),
                };
            });
    };


    const extraRender = ROUTES_FOR_DYNAMIC_REFRESH.includes(router.pathname) ? router.asPath : router.pathname;
    const memoDependency = memoRefresher ? [sections, cmsSections, extraRender] : [extraRender];

    const parsedSectionsMemo = useMemo(() => {
        const parsedSections: MergedSection[] = prepareSections(sections, cmsSections);
        const newSections = prepareCmsSections(parsedSections, cmsSections);

        const sortedSections = [...parsedSections, ...newSections].sort((a, b) => a.order - b.order);

        return sortedSections.map((parsedSection, index) => {
            const Component = () => parsedSection.children(parsedSection.slug, parsedSection.cmsSection);

            if (index > 2) {
                const ComponentDelayedHydration = withHydrationOnDemand({ on: ['visible'] })(Component);
                return <ComponentDelayedHydration key={`${parsedSection.slug}-${parsedSection.order}-${index}`} />;
            }

            return Component();
        });
    }, memoDependency);

    return (
        <StyledComponent className="public-cms-sections-wrapper">
            {parsedSectionsMemo}
        </StyledComponent>
    );
};

export default PublicCmsSectionsWrapper;
