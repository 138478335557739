import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    position: relative;
    width: 100%;
    padding: ${variables.sectionPadding};

    .public-offers-section-offers {
        padding: 0;
    }

    .public-offers-section-packages {
        .control {
            margin-top: 2em;
        }
    }

    @media all and (max-width: ${variables.mobileL}) {
        padding: ${variables.sectionPaddingMobile};
        overflow-x: hidden;

        .layout-container {
            width: 100%;

            .headline {
                padding: 0 5%;
            }
        }

        .public-offers-section-offers {
            padding: 0;
        }
    }
`;
