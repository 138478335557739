import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: .5em;
    box-shadow: 0 1em 1.5em -.9em #7b6c63;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .link-wrapper {
        display: flex;
        min-height: 200px;
        max-height: 200px;
        width: 100%;
        text-decoration: none;
        align-items: center;
    }

    .box-content {
        width: 100%;
        padding: 1em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        transform: translateY(1em);
        transition: .3s transform ease-in-out;

        .box-title {
            font-size: 1.6em;
            font-weight: 800;
            color: white;
        }

        .box-fake-button {
            opacity: 0;
            transition: .3s opacity ease-in-out;
            margin-top: 1em;
            color: white;
            font-size: 0.9em;
            padding: .6em 2em;
            border-radius: 0.5em;
            background-color: #BA9449;

            @media (pointer: fine) {
                &:hover {
                    background-color: #CFB799;
                }
            }
        }
    }

    .background-image {
        position: absolute;
        height: 100%;
        width: 100%;
        min-width: 300px;
        top: 0;
        left: 0;
        z-index: -1;
        transform: scale(1.1);
        transition: .3s transform ease-in-out;
        object-fit: cover;
    }

    &:hover {
        .box-content {
            transform: translateY(0);

            .box-fake-button {
                opacity: 1;
            }
        }

        .background-image {
            transform: scale(1);
        }
    }

    @media all and (max-width: ${variables.tabletL}) {
        .box-content {
            transform: translateY(0);

            .box-button {
                opacity: 1;
            }
        }

        .background-image {
            transform: scale(1);
        }
    }

    @media all and (max-width: ${variables.mobileL}) {
        min-height: 15em;
    }
`;
